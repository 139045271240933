<template>
  <div>
    <carousal-content page="faq" :contents="contents" />

    <!-- ==== form start ==== -->
    <form action="#" method="post" id="question">
      <input
        type="text"
        name="quest"
        id="quetionInput"
        placeholder="Enter Your Question"
        required="required"
      />
    </form>
    <!-- ==== #form end ==== -->

    <!-- ==== faq section start ==== -->
    <section class="faq section__space">
      <div class="container">
        <app-tabs>
          <tab title="Top FAQ">
            <tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading>
                What Responsibilities Do Tenants Have on Rented
                Properties?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading
                >What Claims Can You Claim Compensation for?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading>How long do I have to claim?</template>
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading>
                How long do claims usually take to settle?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading>
                What if I am behind on my rent?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
          </tab>
          <tab title="Shipping With ShipIndia">
            <tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
          </tab>
          <tab title="Package at Warehouse">
            <tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
          </tab>
          <tab title="Pakage Delivery"
            ><tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content></tab
          >
          <tab title="Payment"
            ><tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content></tab
          >
          <tab title="Pakage Delivery"
            ><tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content></tab
          >
          <tab title="ShipIndia"
            ><tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content></tab
          >
          <tab title="Important ShipIndia Shipping Guidelines"
            ><tab-content>
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content></tab
          >
        </app-tabs>
      </div>
    </section>
    <!-- ==== #faq section end ==== -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
import tab from "../components/Tab.vue";
import AppTabs from "../components/AppTabs.vue";
import CarousalContent from "../components/CarousalContent.vue";
import TabContent from "../components/TabContent.vue";
export default {
  components: { CarousalContent, AppTabs, tab, TabContent },
  mounted() {
    new WOW().init();
  },
  data() {
    return {
      visible: false,
      contents: [
        {
          heading: "Top FAQ",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
        {
          heading: "Top FAQ",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
        {
          heading: "Top FAQ",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
      ],
    };
  },
};
</script>

<style>
</style>
