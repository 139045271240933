<template>
  <div class="faq__tab__content">
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h6 class="accordion-header" id="flush-headingshipAcOne">
          <button
            class="accordion-button"
            :class="show ? '' : 'collapsed'"
            type="button"
            @click="update"
          >
            <slot name="heading"></slot>
          </button>
        </h6>
        <transition name="fadeHeight">
          <div
            id="flush-collapseshipAcOne"
            class="accordion-collapse collapse"
            :class="show ? 'show' : ''"
            v-if="show"
          >
            <div class="accordion-body">
              <slot name="content"></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      default: false,
    },
  },
  data() {
    return {
      currentStatus: this.visible,
    };
  },
  computed: {
    show: {
      get() {
        return this.currentStatus;
      },
      set(value) {
        this.currentStatus = value;
      },
    },
  },
  methods: {
    update() {
      this.show = !this.show;
    },
  },
};
</script>

<style>

</style>