<template>
  <div class="faq__area">
    <div class="faq__row">
      <div class="faq__tabs__row">
        <div class="faq__tabs__button__wrapper">
          <a
            v-for="(tab, index) in tabs"
            :key="index"
            class="faq__tab__button"
            :class="index === activeTab ? 'faq__tab__button__active' : ''"
            @click="selectTab(index)"
            >{{ tab.title }}</a
          >
        </div>
      </div>
      <div class="faq__tabs__col">
      <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTabs",
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  data() {
    return {
      activeTab: 0,
      tabs: [],
    };
  },
  methods: {
    selectTab(i) {
      this.activeTab = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
  },
};
</script>

<style scoped>
.faq__tabs__button__wrapper a {
  cursor: pointer;
}
</style>